import {
  AuthService,
  ICommunityService,
  ICorporationModelService,
} from '@tokens/injection.tokens';
import { IRegexService, RegexService } from '@services/common/regex.service';
import { ApiInterceptor } from '@interceptors/api.interceptor';
import { ApplicationComponent } from './application.component';
import { ApplicationRoutingModule } from './application-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Community } from '@models/communities/community.model';
import { Corporation } from '@models/corporations/corporation.model';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { MenuItem } from '@models/layout/menu.model';
import { ModelService } from '@services/common/model.service';
import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '@models/account/user.model';
import { createTranslateLoader } from '@functions/translate';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [ApplicationComponent, NotFoundPageComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApplicationRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxSpinnerModule.forRoot({ type: 'ball-spin-clockwise' }),
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-fixed',
      preventDuplicates: true,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: 'API_URL', useValue: environment.api },
    {
      provide: AuthService,
      useFactory: () => new ModelService<User>(),
    },

    {
      provide: 'MenuService',
      useFactory: () => new ModelService<MenuItem[]>(),
    },
    {
      provide: ICorporationModelService,
      useFactory: () => new ModelService<Corporation>(),
    },
    {
      provide: ICommunityService,
      useFactory: () => new ModelService<Community>(),
    },
    {
      provide: IRegexService,
      useClass: RegexService,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [ApplicationComponent],
})
export class ApplicationModule {}
