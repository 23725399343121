import Swal from 'sweetalert2';
import { SweetAlertResult } from 'sweetalert2';

export function alertFire(text: string): Promise<SweetAlertResult> {
  let confirmTxt = 'Confirm';

  if (text.toLowerCase().includes('remove')) confirmTxt = 'Yes, Remove';
  if (text.toLowerCase().includes('delete')) confirmTxt = 'Yes, Delete';
  if (text.toLowerCase().includes('archive')) confirmTxt = 'Yes, Archive';
  if (text.toLowerCase().includes('unarchive')) confirmTxt = 'Yes, Unarchive';

  return Swal.fire({
    title: 'Are you sure?',
    text: text,
    iconHtml: '',
    showCancelButton: true,
    confirmButtonText: confirmTxt,
    reverseButtons: true,
    customClass: {
      confirmButton: 'swal2-confirm',
      cancelButton: 'swal2-cancel',
      popup: 'swal2-custom',
    },
  });
}
