import { MenuItem } from '@models/layout/menu.model';
import { Router } from '@angular/router';
import { User } from '@models/account/user.model';
import { map } from 'lodash';
import { of } from 'rxjs';

export function getRouteByRole(user: User): string {
  const roleNames = map(user.roles, r => r.name);
  if (roleNames.includes('ADMIN')) {
    return '/admin';
  }
  if (
    roleNames.includes('CORPORATION_ADMIN') ||
    roleNames.includes('CORPORATION_RESTRICTED')
  ) {
    const role = user.roles.find(
      x => x.name === 'CORPORATION_ADMIN' || x.name === 'CORPORATION_RESTRICTED'
    );
    if (role?.corporationId) {
      return `/corporations/${role.corporationId}`;
    }
  }
  if (
    roleNames.includes('COMMUNITY_ADMIN') ||
    roleNames.includes('COMMUNITY_INTERVIEWER') ||
    roleNames.includes('COMMUNITY_CAREGIVER') ||
    roleNames.includes('COMMUNITY_EDITOR')
  ) {
    const role = user.roles.find(x => x.name === 'COMMUNITY_ADMIN');
    if (role?.communityId) {
      return `/communities/${role.communityId}`;
    }
  }

  if (roleNames.includes('PREMIUM_CONTENT')) {
    return '/content';
  }
  return '/';
}

export function getMenuByRole(user: User): MenuItem[] {
  const menu: MenuItem[] = [];
  const roleNames = map(user.roles, r => r.name);
  if (roleNames.includes('ADMIN')) {
    menu.push({
      id: 1,
      label: 'Admin Panel',
      link: '/admin',
      icon: 'bx bx-grid-alt',
    });
  }
  if (
    roleNames.includes('CORPORATION_ADMIN') ||
    roleNames.includes('CORPORATION_RESTRICTED')
  ) {
    const role = user.roles.find(
      x => x.name === 'CORPORATION_ADMIN' || x.name === 'CORPORATION_RESTRICTED'
    );
    if (role?.corporationId) {
      menu.push({
        id: 1,
        label: 'Corporate Portal',
        link: `/corporations/${role.corporationId}`,
        icon: 'bx bx-shield-alt-2',
      });
    }
  }
  if (
    roleNames.includes('COMMUNITY_ADMIN') ||
    roleNames.includes('COMMUNITY_INTERVIEWER') ||
    roleNames.includes('COMMUNITY_CAREGIVER') ||
    roleNames.includes('COMMUNITY_EDITOR')
  ) {
    const role = user.roles.find(x => x.name === 'COMMUNITY_ADMIN');
    if (role?.communityId) {
      menu.push({
        id: 1,
        label: 'Community Portal',
        link: `/communities/${role.communityId}`,
        icon: 'bx bx-buildings',
      });
    }
  }

  if (roleNames.includes('PREMIUM_CONTENT')) {
    menu.push({
      id: 1,
      label: 'Content Panel',
      link: '/content',
      icon: 'bx bx-customize',
    });
  }
  return menu;
}

export function notfound(router: Router) {
  router.navigate(['/404']).then();
  return of(false);
}
