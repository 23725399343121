import { AuthenticationGuard } from '../core/guards/authentication.guard';
import { NgModule } from '@angular/core';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PlayerComponent } from './pages/player/player.component';
import { RouterModule } from '@angular/router';
import { Routes } from '@angular/router';
import { SurveyRedirectionComponent } from './pages/survey-redirection/survey-redirection.component';
import { UnsubscribeComponent } from './pages/unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('../administration/administration.module').then(
        m => m.AdministrationModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'corporations',
    loadChildren: () =>
      import('../corporations/corporations.module').then(
        m => m.CorporationsModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'communities',
    loadChildren: () =>
      import('../communities/communities.module').then(
        m => m.CommunitiesModule
      ),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'content',
    loadChildren: () =>
      import('../content/content.module').then(m => m.ContentModule),
    canActivate: [AuthenticationGuard],
  },

  {
    path: 'account',
    loadChildren: () =>
      import('../account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'unsubscribe/:userId',
    component: UnsubscribeComponent,
    pathMatch: 'full',
  },
  {
    path: 'sv/:code',
    component: SurveyRedirectionComponent,
    pathMatch: 'full',
  },
  {
    path: 'player/:videoId/:userId',
    component: PlayerComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('../web-site/web-site.module').then(m => m.WebSiteModule),
  },
  {
    path: '404',
    component: NotFoundPageComponent,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class ApplicationRoutingModule {}
